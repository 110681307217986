// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "[data-v-8405abfc]:export{xs:667.98;sm:947.98;md:1023.98;lg:1229.98}.JxWEvnQu[data-v-8405abfc]{background-color:var(--color-blue);display:flex;justify-content:center;margin-bottom:4vw}.-kRoiR9D[data-v-8405abfc]{position:relative}.DKX-tMRj[data-v-8405abfc]{max-height:100vh}.BNyohvic[data-v-8405abfc]{bottom:0;left:50%;position:absolute;transform:translate(-50%,50%);width:10vw}.tAX1wlnr[data-v-8405abfc]{bottom:30%;color:var(--color-white);font-size:1.13rem;font-weight:500;letter-spacing:-.05em;line-height:1.2;padding-left:3rem;position:absolute;right:14%;text-align:right}.tAX1wlnr[data-v-8405abfc]:before{background:url(/landing/arrow.svg) 50%/contain no-repeat;content:\"\";height:2rem;left:0;position:absolute;top:50%;transform:translateY(-50%);width:1.5rem}.tAX1wlnr[data-v-8405abfc]:after{background:url(/landing/double-arrow.svg) 50%/contain no-repeat;bottom:-5rem;content:\"\";height:2rem;position:absolute;right:0;width:3rem}@media(max-width:1023.98px){.tAX1wlnr[data-v-8405abfc]{bottom:22%;font-size:1rem}.tAX1wlnr[data-v-8405abfc]:after{display:none}}@media(max-width:667.98px){.tAX1wlnr[data-v-8405abfc]{bottom:15%;font-size:.81rem;line-height:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": "JxWEvnQu",
	"bannerContent": "-kRoiR9D",
	"bannerImage": "DKX-tMRj",
	"bannerLines": "BNyohvic",
	"bannerDescription": "tAX1wlnr"
};
module.exports = ___CSS_LOADER_EXPORT___;
